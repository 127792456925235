class BaseForm {
    constructor(formClass) {
        this.$form = $(formClass);
        this.$form.on('submit', this.processSubmit.bind(this));
    }

    processSubmit(event) {
        event.preventDefault();
        this.clearErrors();

        $.ajax({
            type: 'POST',
            url: this.apiUrl,
            xhrFields: {
                withCredentials: true
            },
            complete: this.onComplete.bind(this),
            data: JSON.stringify({ partner_user: this.serializedData() }),
            contentType: 'application/json; charset=utf-8',
        })
    }

    clearErrors() {
        this.$form.find('.error').remove();
    }

    onComplete(response) {
        if (this.isSuccessResonse(response)) {
            this.onSuccess(response);
        } else if (this.isResponseWithErrors(response)) {
            this.displayErrors(response);
        }
    }

    resetForm() {
        this.$form[0].reset();
    }

    serializedData() {
        const result = {};

        this.$form.serializeArray().forEach(field => {
            result[field.name] = field.value;
        });

        return result;
    }

    displayErrors(response) {
        const errors = response.responseJSON.errors;

        for (const field in errors) {
            if (errors.hasOwnProperty(field)) {
                this.displayFieldErrors(field, errors[field]);
            }
        }
    }

    displayFieldErrors(field, errors) {
        const $field = this.$form.find(`[name=${field}]`);
        $field.parent().append(this.errorDiv(errors.join(', ')));
    }

    errorDiv(error) {
        return `<div class='error'>${error}</div>`;
    }
}





class SignUpForm extends BaseForm {
    constructor(formClass, { withLabels = false, withPlaceholders = true } = {}) {
        super(formClass);
        this.withLabels = withLabels;
        this.withPlaceholders = withPlaceholders;
        this.apiUrl = 'https://backoffice.n1.partners/api/client/partner';
        // this.populateFields();
    }

    serializedData() {
        const result = super.serializedData();

        if (result.receive_newsletter) {
            result.receive_newsletter = true
        }

        return result;
    }

    onSuccess(response) {
        this.resetForm();
        $('#modalSignUp').modal('close');
        setTimeout(function () {
            $('#modalThanksReg').modal('open')
        },1500)
        if (!response.responseJSON.verified) {
            this.onNotVerified();
        }
    }

    onNotVerified() {
        if ($('#notVerifiedModal').length > 0) {
            $('#notVerifiedModal').modal('show');
        }
    }

    isSuccessResonse(response) {
        return response.status === 201;
    }

    isResponseWithErrors(response) {
        return response.status === 422;
    }

    // populateFields() {
    //     const $parent = this.$form.find('#register_fields');
    //
    //     this.requestFields(fields => {
    //         fields.forEach(field => $parent.append(this.fieldHtml(field)));
    //     });
    // }

    // requestFields(callback) {
    //     $.ajax({
    //         type: 'GET',
    //         url: 'https://backoffice.n1.partners/api/client/anonymous/current_casino',
    //         contentType: 'application/json; charset=utf-8',
    //         complete: (response) => callback(response.responseJSON.profile_fields)
    //     });
    // }
    //
    // fieldHtml(field) {
    //     if (field.type === 'boolean') {
    //         return this.checkboxHtml(field);
    //     } else if (field.type === 'text') {
    //         return this.textFieldHtml(field);
    //     } else {
    //         return this.stringFieldHtml(field);
    //     }
    // }

    // checkboxHtml({ name, label, required }) {
    //     return `
    //   <div class='checkbox'>
    //     <label>
    //       <input type='checkbox' name='${name}' value='1'>
    //       <span class='checkbox__input'></span>
    //       ${label + (required ? ' *' : '')}
    //     </label>
    //   </div>
    // `
    // }

    // textFieldHtml({ name, label, required }) {
    //     return `
    //   <div class='form-group'>
    //     ${this.labelHtml(label)}
    //     <textarea placeholder='${this.placeholder(label, required)}'
    //               name=${name}
    //               class='form-control'></textarea>
    //   </div>
    // `
    // }

    // stringFieldHtml({ name, label, required }) {
    //     return `
    //   <div class='form-group'>
    //     ${this.labelHtml(label)}
    //     <input type='text'
    //            placeholder='${this.placeholder(label, required)}'
    //            name=${name}
    //            class='form-control' />
    //   </div>
    // `
    // }

    placeholder(label, required) {
        if (this.withPlaceholders) {
            return label + (required ? ' *' : '');
        } else {
            return '';
        }
    }

    labelHtml(label) {
        if (this.withLabels) {
            return `<label class='control-label'>${label}</label>`;
        } else {
            return '';
        }
    }
}




class SignInForm extends BaseForm {
    constructor(formClass) {
        super(formClass);
        this.apiUrl = 'https://backoffice.n1.partners/api/client/partner/sign_in';
    }

    onSuccess(response) {
        $('#modalLogin').modal('close');
        window.location.href = 'https://backoffice.n1.partners/partner';
    }

    displayErrors(response) {
        const error = response.responseJSON.error;
        this.$form.prepend(this.errorDiv(error));
    }

    isSuccessResonse(response) {
        return response.status === 201;
    }

    isResponseWithErrors(response) {
        return response.status === 401;
    }
}



class ResetPasswordForm extends BaseForm {
    constructor(formClass) {
        super(formClass);
        this.apiUrl = 'https://backoffice.n1.partners/api/client/partner/password';
    }

    onSuccess() {
        this.resetForm();
        $('#modalReset').modal('close');

        setTimeout(function () {
            $('#modalThanksReset').modal('open')
        },1500)
    }

    isSuccessResonse(response) {
        return response.status === 201 || response.status === 200;
    }

    isResponseWithErrors(response) {
        return response.status === 422;
    }
}



class StagProcessor {
    constructor() {
        this.params = new URLSearchParams(window.location.search);
        this.stag = this.params.get('stag');
        this.params.delete('stag');
    }

    process() {
        if (this.stag) {
            this.trackStag();
            this.stripStag();
        }
    }

    trackStag() {
        $.ajax({
            url: 'https://backoffice.n1.partners/api/client/partner/track_stag',
            type: 'POST',
            xhrFields: {
                withCredentials: true
            },
            data: JSON.stringify({ stag: this.stag }),
            contentType: 'application/json; charset=utf-8'
        });
    }

    stripStag() {
        const query = this.params.toString();
        let path = window.location.pathname;

        if (query.length > 0) {
            path = `${path}?${query}`;
        }

        window.history.replaceState(null, null, path);
    }
}


const processor = new StagProcessor();
processor.process();

$(document).ready(function () {

    new ResetPasswordForm('.new-password-form');
    new SignInForm('.login-form');
    new SignUpForm('.register-form',
        { withLabels: false,
            withPlaceholders: false });
    new SignUpForm('.register-form-nomodal',
        { withLabels: false,
            withPlaceholders: false });

});
